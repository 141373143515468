<template>
  <div v-if="isAdmin" class="p-grid">
    <div class="p-col-12">
      <div class="card card-w-title">
        <ProgressBar mode="indeterminate" v-if="processing"/>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-2">
            <label>Secuencial Matrícula</label>
            <InputText v-model="sequential" type="text"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Saldo</label>
            <InputText v-model="saldo" type="text"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Valor Pagado</label>
            <InputText v-model="valor_pagado" type="text"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Cuota Mensual</label>
            <InputText v-model="cuota_mensual" type="numeric"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Cuotas Restantes</label>
            <InputText v-model="cuotas_restantes" type="numeric"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>Fecha Primer Pago</label>
            <InputText v-model="fecha_primer_pago" type="date" style="height:34px"/>
          </div>
          <div class="p-field p-col-12 p-md-2">
            <label>&nbsp;</label>
            <Button class="btn btn-primary" style="text-align: center" @click="add">Guardar</Button>
          </div>
        </div>

      </div>
    </div>
    <div class="p-col-12">
      <div class="card card-w-title">
        <div class="p-fluid p-formgrid p-grid">
          <DataTable :value="list" class="p-datatable-responsive" selectionMode="single" dataKey="id" :paginator="true"
                     paginatorPosition="bottom" :rows="10">

            <Column field="id" header="Codigo"></Column>
            <Column field="country.name" header="País" ></Column>
            <Column field="enrollment.sequential" header="Matrícula" ></Column>
            <Column field="created_at" header="Fecha Creación " ></Column>
          </DataTable>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>

  </div>
</template>

<script>
import service from '../../service/catalog.service'
import {mapState} from "vuex";
import bouncer from "../../../../helpers/bouncer";

export default {
  mixins: [bouncer],
  name: 'fixImport',
  data() {
    return {
      processing: false,
      list: [],
      sequential: null,
      valor_pagado: null,
      saldo: null,
      cuota_mensual: null,
      cuotas_restantes: null,
      fecha_primer_pago: null,

    }
  },
  carService: null,
  eventService: null,
  nodeService: null,
  created() {

  },
  mounted() {
      this.getData()
  },
  methods: {
    add() {
      if (!this.sequential || !this.saldo|| !this.valor_pagado  || !this.cuota_mensual || !this.cuotas_restantes || !this.fecha_primer_pago) {
        alert('todos los campos son obligatorios')
        return;
      }
      this.processing = true;
      const params = {
        sequential: this.sequential,
        valor_pagado: this.valor_pagado,

        cuota_mensual: this.cuota_mensual,
        saldo: this.saldo,
        cuotas_restantes: this.cuotas_restantes,
        fecha_primer_pago: this.fecha_primer_pago,
      };

      service.save('fix-data-import', params,undefined).then(() => {
        this.getData();
        this.$toast.add({
          severity: 'success',
          summary: 'Registro guardado',
          detail: 'Se guardó correctamente',
          life: this.$utils.toastLifeTime()
        });

      }).catch((x) => {
        const message = x.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.processing = false;
      });
    },

    getData() {
      this.processing = true;
      service.get('fix-data-import',{}).then((x) => {
       this.list=(x.data.list)
      }).catch((x) => {
        const message = x.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => {
        this.processing = false;
      });
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
  }
}
</script>

<style scoped>
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-progressbar {
  height: 1px;
}
</style>
